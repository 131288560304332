import React, { Component } from 'react';
import { Box, Hidden, Slide, Typography } from '@mui/material';
import { Translation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IHomeProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IHomeState {}

export class Home extends Component<IHomeProps, IHomeState> {
  readonly state: IHomeState;

  constructor(props: IHomeProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Box className={'app-home'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Hidden mdUp>
          <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={2000}>
            {/*Content for small and below display size*/}
            <Typography variant={'h3'} style={{ fontWeight: 'bold', color: '#fff' }}>
              <Translation>{(t) => t('welcome')}</Translation>
            </Typography>
          </Slide>
        </Hidden>

        {/*Content for medium and above display size*/}
        <Hidden smDown>
          <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={2000}>
            <Typography variant={'h1'} style={{ fontWeight: 'bold', color: '#fff' }}>
              <Translation>{(t) => t('home.welcome')}</Translation>
            </Typography>
          </Slide>
        </Hidden>
      </Box>
    );
  }
}
