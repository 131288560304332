import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const i18nBackendOptions = {
  loadPath: '/locales/{{ns}}_{{lng}}.json'
};

const userLang = getCurrentLanguage();

i18n
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  .use(LanguageDetector)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // detection: {
    //   order: ['navigator']
    // },

    debug: process.env.NODE_ENV === 'development',

    lng: userLang,
    fallbackLng: 'en',
    supportedLngs: ['en', 'en-US', 'en-GB', 'de', 'de-DE'],
    load: 'currentOnly',

    interpolation: {
      // not needed for react as it escapes by default
      escapeValue: false
    },
    backend: i18nBackendOptions
  });

export function getCurrentLanguage() {
  return navigator.language;
}
