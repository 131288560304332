import React, { Component } from 'react';
import { Skill as SkillModel } from '../../models/Skills';
import { Skill } from './Skill';
import { Box } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISkillListState {}

interface ISkillListProps {
  skillsToRender: SkillModel[];
}

export class SkillList extends Component<ISkillListProps, ISkillListState> {
  public state: ISkillListState;
  public readonly props!: ISkillListProps;

  constructor(props: ISkillListProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Box mx={5} mb={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {this.props.skillsToRender.map((skill: SkillModel, index: number) => {
          return <Skill key={index} name={skill.name} rating={skill.rating} />;
        })}
      </Box>
    );
  }
}
