import React, { Component } from 'react';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { SchoolOutlined } from '@mui/icons-material';
import { Education } from '../../models/Education';
import EducationDetails from './EducationDetails';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEducationEntryState {}

export interface IEducationEntryProps {
  data: Education;
  elementId: number;
  displayConnector: boolean;

  // animate: boolean;
  timeout: number;
}

class EducationEntry extends Component<IEducationEntryProps, IEducationEntryState> {
  public state!: IEducationEntryState;
  public props!: IEducationEntryProps;

  constructor(props: IEducationEntryProps) {
    super(props);

    this.state = { animate: false };
  }

  render() {
    const { data, displayConnector, timeout } = this.props;

    const content = (
      <EducationDetails
        institutionName={data.educationalInstitutionName}
        institutionLocation={data.educationalInstitutionLocation}
        achievedDegree={data.achievedDegree}
        thesis={data.thesis}
        dateFrom={data.fromDate}
        dateTo={data.toDate}
        animationTimeout={timeout}
      />
    );

    return (
      <TimelineItem style={{ margin: '10px' }}>
        <TimelineSeparator>
          <TimelineDot color={'secondary'} variant={'outlined'}>
            <SchoolOutlined color={'secondary'} fontSize={'large'} />
          </TimelineDot>
          {displayConnector && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>{content}</TimelineContent>
      </TimelineItem>
    );
  }
}

export default EducationEntry;
