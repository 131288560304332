import React, { Component } from 'react';
import { Grid, Typography } from '@mui/material';
import { Translation } from 'react-i18next';
import { SpaceBox } from '../../theming/SpaceBox';
import { RoundedTypography } from '../../theming/RoundedTypography';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProjectDataEntryState {}

export interface IProjectDataEntryProps {
  title: string;
  content: string;
  listContent: boolean;
}

class ProjectDataEntry extends Component<IProjectDataEntryProps, IProjectDataEntryState> {
  public state!: IProjectDataEntryState;
  public props!: IProjectDataEntryProps;

  constructor(props: IProjectDataEntryProps) {
    super(props);

    this.getList = this.getList.bind(this);
  }

  getList() {
    return this.props.content.split(',');
  }

  render() {
    const { title, content, listContent } = this.props;

    const mobileContent = (
      <Grid item xs={12} sx={{ display: { xs: 'flex', md: 'none' } }}>
        <Grid item xs={12}>
          <Grid item xs={12} justifyContent={'center'} alignContent={'center'}>
            <Typography variant={'h6'} align={'center'}>
              <Translation>{(t) => t(`projectsPage.${title}`)}</Translation>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {listContent && (
              <SpaceBox>
                {this.getList().map((value, index) => (
                  <RoundedTypography align={'center'} key={index}>
                    {value}
                  </RoundedTypography>
                ))}
              </SpaceBox>
            )}
            {!listContent && <Typography align={'center'}>{content}</Typography>}
          </Grid>
        </Grid>
      </Grid>
    );

    const regularContent = (
      <Grid item xs={12} sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Grid item xs={6}>
          <Typography variant={'h6'} align={'right'} justifyContent={'center'}>
            <Translation>{(t) => t(`projectsPage.${title}`)}</Translation>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {listContent && (
            <SpaceBox pl={2} justifyContent={'left'} alignItems={'center'} sx={{ display: 'flex' }}>
              {this.getList().map((value, index) => (
                <RoundedTypography key={index}>{value}</RoundedTypography>
              ))}
            </SpaceBox>
          )}
          {!listContent && (
            <Typography align={'left'} justifyContent={'center'} pl={2}>
              {content}
            </Typography>
          )}
        </Grid>
      </Grid>
    );

    return (
      <>
        {mobileContent}
        {regularContent}
      </>
    );
  }
}

export default ProjectDataEntry;
