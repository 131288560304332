import React, { Component, ReactElement } from 'react';

import { Education, Education as EducationModel } from '../../models/Education';
import { Box } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import EducationEntry from './EducationEntry';
import { TransitionGroup } from 'react-transition-group';
import { timelineContentClasses, timelineItemClasses } from '@mui/lab';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEducationListState {}

interface IEduactionListProps {
  dataToRender: Array<EducationModel>;
}

class EducationList extends Component<IEduactionListProps, IEducationListState> {
  public readonly props!: IEduactionListProps;

  constructor(props: IEduactionListProps) {
    super(props);

    // binding of this context
    this.renderEntries = this.renderEntries.bind(this);
  }

  calculateTimeout(currentItemIndex: number) {
    const animationTimePerItem = 100;
    const idleTimeBetweenAnimations = 300;

    return idleTimeBetweenAnimations * currentItemIndex + animationTimePerItem;
  }

  renderEntries() {
    const educationEntries = new Array<ReactElement<EducationEntry>>();

    this.props.dataToRender.forEach((data: EducationModel, index: number, array: Education[]) => {
      const displayConnector = index < array.length - 1;

      educationEntries.push(
        <EducationEntry
          key={index}
          elementId={index}
          data={data}
          displayConnector={displayConnector}
          timeout={this.calculateTimeout(array.length - index)}
        />
      );
    });

    return educationEntries;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  componentMount() {}

  render() {
    const mobileContent = (
      <Timeline
        sx={{
          display: { xs: 'inherit', md: 'none' },

          [`& .${timelineContentClasses.root}`]: {
            flex: 1
          },
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0
          },
          padding: 0
        }}
        position={'right'}
      >
        <TransitionGroup>{this.renderEntries()}</TransitionGroup>
      </Timeline>
    );

    const regularContent = (
      <Timeline
        sx={{
          display: { xs: 'none', md: 'inherit' },

          [`& .${timelineContentClasses.root}`]: {
            flex: 0.7
          },
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0.1
          }
        }}
        position={'right'}
      >
        <TransitionGroup>{this.renderEntries()}</TransitionGroup>
      </Timeline>
    );

    return (
      <Box>
        {mobileContent}
        {regularContent}
      </Box>
    );
  }
}

export default EducationList;
