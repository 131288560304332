import React, { Component } from 'react';
import { Backdrop, CircularProgress, Grid, Typography } from '@mui/material';
import { SkillsService } from '../services/SkillsService';
import { SkillList } from '../components/skills/SkillList';
import { Skill } from '../models/Skills';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISkillProps {}

interface ISkillsState {
  skills: Skill[];
  loading: boolean;
}

export class Skills extends Component<ISkillProps, ISkillsState> {
  public state: ISkillsState;

  private readonly skillsService = new SkillsService();

  constructor(props: ISkillProps) {
    super(props);

    this.state = { skills: [], loading: false };
  }

  componentDidMount() {
    this.loadSkills();
  }

  loadSkills() {
    this.setState({ loading: true });

    this.skillsService.getSkills().then((response) => {
      const sortedSkills = response;
      sortedSkills.sort((a: Skill, b: Skill) => {
        return b.rating - a.rating;
      });
      this.setState({ skills: sortedSkills, loading: false });
    });
  }

  render() {
    const { loading } = this.state;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} mb={3} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant={'h3'} style={{ fontWeight: 'bold', color: '#fff' }}>
              Mein Fähigkeiten Portfolio
            </Typography>
          </Grid>
          <Grid item xs={12} mx={5}>
            <SkillList skillsToRender={this.state.skills} />
          </Grid>
        </Grid>
        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}
