import { theme } from './muiTheme';
import { styled, Typography } from '@mui/material';

export const RoundedTypography = styled(Typography)({
  border: '1px solid',
  borderRadius: '1em',
  padding: '0.4em 0.6em',
  margin: '0.2em',
  borderColor: theme.palette.primary.main
});
