// This import loads the firebase namespace.
import { initializeApp } from 'firebase/app';
// These imports load individual services into the firebase namespace.
import { getAnalytics } from 'firebase/analytics';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';

import React, { Suspense } from 'react';
import './index.css';
import App from './App';

import './services/i18n';

import * as serviceWorker from './serviceWorker';
import { ApplicationConfig } from './models/configuration/ApplicationConfig';
import { createRoot } from 'react-dom/client';

// eslint-disable-next-line
const app_config: ApplicationConfig = (globalThis as any).APP_CONFIG;

const firebaseConfig = {
  apiKey: app_config.API_KEY,
  databaseURL: app_config.DATABASE_URL,
  projectId: app_config.PROJECT_ID,
  appId: app_config.APP_ID,
  measurementId: app_config.MEASUREMENT_ID
};

initializeApp(firebaseConfig);
getAnalytics();

// init firestore
// Initialize your Web app as described in the Get started for Web
// Firebase previously initialized using firebase.initializeApp().
// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  const db = getFirestore();
  connectFirestoreEmulator(db, 'localhost', 8080);
}

const domNode = document.getElementById('root');
if (!domNode) throw new Error('Failed to find the root element');
const root = createRoot(domNode);

// render the app
root.render(
  <Suspense fallback={null}>
    <App />
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
