import React, { Component } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { Translation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IContactProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IContactState {}

export class Contact extends Component<IContactProps, IContactState> {
  readonly state: IContactState;

  constructor(props: IContactProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Container>
        <Grid container spacing={2} justifyContent={'center'}>
          <Grid item>
            <Typography variant={'h3'} style={{ fontWeight: 'bold', color: '#fff' }}>
              <Translation>{(t) => t('contactPage.title')}</Translation>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
