import React, { Component } from 'react';
import { Box, CardActionArea, CardContent, CardHeader, Collapse, Grid, Grow } from '@mui/material';
import { Project as ProjectModel } from '../../models/Project';
import ProjectDataEntry from './ProjectDataEntry';
import { ProjectCard } from '../../theming/ProjectCard';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface IProjectState {
  expanded: boolean;
  hovered: boolean;
}

export interface IProjectProps {
  key?: number;
  title: string;
  dateFrom: Date;
  dateTo: Date;

  projectData: ProjectModel;

  animationTimeout: number;
}

interface ProjectDisplayData {
  index: number;
  title: string;
  content: string;
  isArrayContent: boolean;
}

class Project extends Component<IProjectProps, IProjectState> {
  public state!: IProjectState;
  public props!: IProjectProps;

  private fieldsToDisplay: Array<string> = [
    'companyName',
    'branch',
    'role',
    'tasks',
    'usedFrameworksAndLibraries',
    'usedLanguages',
    'usedMethods',
    'usedTechnologies',
    'usedTools'
  ];

  private data: Array<ProjectDisplayData> = [];

  constructor(props: IProjectProps) {
    super(props);

    this.state = { expanded: false, hovered: false };

    // Binding this keyword
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.toggleHovered = this.toggleHovered.bind(this);
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  toggleHovered() {
    this.setState({ hovered: !this.state.hovered });
  }

  componentDidMount() {
    this.createOutputData();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  componentDidUpdate() {}

  createOutputData() {
    Object.entries(this.props.projectData).forEach(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (value: [string, any], index: number) => {
        if (this.fieldsToDisplay.includes(value[0])) {
          const fieldName = value[0];
          const fieldValue = Array.isArray(value[1]) ? value[1].join(', ') : value[1];

          this.data.push({
            title: fieldName,
            content: fieldValue,
            index: index,
            isArrayContent: Array.isArray(value[1])
          });
        }
      }
    );
  }

  render() {
    const { expanded } = this.state;
    const { animationTimeout } = this.props;

    let icon;

    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };

    if (expanded) {
      icon = <ExpandLess />;
    } else {
      icon = <ExpandMore />;
    }

    return (
      <Box mb={2}>
        <Grow in={true} timeout={animationTimeout} mountOnEnter unmountOnExit>
          <ProjectCard
            onMouseOver={this.toggleHovered}
            onMouseOut={this.toggleHovered}
            onClick={this.handleExpandClick}
          >
            <CardActionArea>
              <CardHeader
                title={this.props.title}
                subheader={
                  this.props.dateFrom.toLocaleDateString(navigator.language, dateOptions) +
                  ' - ' +
                  this.props.dateTo.toLocaleDateString(navigator.language, dateOptions)
                }
              />

              <Collapse in={expanded} timeout={500} unmountOnExit>
                <CardContent>
                  <Grid container spacing={1}>
                    {this.data.map((item, index) => (
                      <ProjectDataEntry
                        key={index}
                        title={item.title}
                        content={item.content}
                        listContent={item.isArrayContent}
                      />
                    ))}
                  </Grid>
                </CardContent>
              </Collapse>

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>{icon}</Box>
            </CardActionArea>
          </ProjectCard>
        </Grow>
      </Box>
    );
  }
}

export default Project;
