import { createTheme, responsiveFontSizes } from '@mui/material';

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#336699',
        light: '#6693ca',
        dark: '#003c6b',
        contrastText: '#fff'
      },
      secondary: {
        main: '#c0c0c0',
        light: '#f3f3f3',
        dark: '#909090',
        contrastText: '#000'
      }
    },
    components: {
      MuiCardHeader: {
        styleOverrides: {
          root: {
            textAlign: 'center'
          },
          title: {
            fontWeight: 'bold'
          }
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            color: '#336699'
          }
        }
      }
    }
  })
);
