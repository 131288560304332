import React, { Component } from 'react';
import Project from './Project';
import { Project as ProjectModel } from '../../models/Project';
import { Box } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProjectListState {}

interface IProjectListProps {
  projectsToRender: Array<ProjectModel>;
}

export class ProjectList extends Component<IProjectListProps, IProjectListState> {
  public state: IProjectListState;
  public readonly props!: IProjectListProps;

  constructor(props: IProjectListProps) {
    super(props);
    this.state = {};
  }

  calculateTimeout(currentItemIndex: number) {
    const animationTimePerItem = 100;
    const idleTimeBetweenAnimations = 300;

    return idleTimeBetweenAnimations * currentItemIndex + animationTimePerItem;
  }

  render() {
    return (
      <Box>
        {this.props.projectsToRender.map((project: ProjectModel, index: number) => {
          return (
            <Project
              key={index}
              title={project.title}
              dateFrom={project.fromDate}
              dateTo={project.toDate}
              projectData={project}
              animationTimeout={this.calculateTimeout(index)}
            />
          );
        })}
      </Box>
    );
  }
}
