import React, { Component } from 'react';
import { Box, Grid, Grow, Paper, Typography } from '@mui/material';
import { Translation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IWorkExperienceState {}

export interface IWorkExperienceProps {
  employerName: string;
  employmentPosition: string;
  employerCity: string;
  mainActivity: string;
  subActivities: Array<string>;
  dateFrom: Date;
  dateTo: Date;

  animationTimeout: number;
}

class WorkExperienceDetails extends Component<IWorkExperienceProps, IWorkExperienceState> {
  public state!: IWorkExperienceState;
  public props!: IWorkExperienceProps;

  constructor(props: IWorkExperienceProps) {
    super(props);
  }

  render() {
    const {
      employerName,
      employmentPosition,
      employerCity,
      mainActivity,
      subActivities,
      dateFrom,
      dateTo,
      animationTimeout
    } = this.props;

    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit'
    };

    const startDate = <>{dateFrom.toLocaleDateString(navigator.language, dateOptions)}</>;
    let endDate;
    if (!isNaN(dateTo.valueOf())) {
      endDate = <>{dateTo.toLocaleDateString(navigator.language, dateOptions)}</>;
    } else {
      endDate = <Translation>{(t) => t('employmentHistoryPage.today')}</Translation>;
    }

    return (
      <Grow in={true} timeout={animationTimeout} mountOnEnter unmountOnExit>
        <Paper elevation={3} color={'secondary'} style={{ padding: '4px 15px 10px' }}>
          <Typography variant={'h5'} style={{ fontWeight: 'bold' }}>
            {employmentPosition}
          </Typography>
          <Typography variant={'body1'}>{employerName}</Typography>
          <Typography variant={'body1'}>{employerCity}</Typography>
          <Typography color="textSecondary">
            {startDate} - {endDate}
          </Typography>

          {mainActivity.length != 0 && (
            <Box mb={2}>
              <Grid>
                <Grid item>
                  <Typography color={'textPrimary'}>
                    <span style={{ fontWeight: 'bold' }}>
                      <Translation>{(t) => t('employmentHistoryPage.main-activity')}</Translation>:
                    </span>
                  </Typography>
                </Grid>
                <Grid item>{mainActivity}</Grid>
              </Grid>
            </Box>
          )}

          {subActivities.length != 0 && (
            <Box mb={2}>
              <Grid>
                <Grid item>
                  <Typography color={'textPrimary'}>
                    <span style={{ fontWeight: 'bold' }}>
                      <Translation>{(t) => t('employmentHistoryPage.sub-activities')}</Translation>:
                    </span>
                  </Typography>
                </Grid>
                <Grid item>
                  {subActivities.length != 0 && subActivities.map((entry, index) => <div key={index}>{entry}</div>)}
                </Grid>
              </Grid>
            </Box>
          )}
        </Paper>
      </Grow>
    );
  }
}

export default WorkExperienceDetails;
