import { lighten, LinearProgress, styled } from '@mui/material';

export const SkillAssessmentBar = styled(LinearProgress)({
  height: 20,
  width: '100%',
  borderRadius: 20,
  backgroundColor: lighten('#3095e1', 0.5),
  bar: {
    borderRadius: 20,
    backgroundColor: '#fff'
  }
});
