import React, { Component } from 'react';
import { Backdrop, Box, CircularProgress, Grid, Typography } from '@mui/material';
import { WorkExperience as WorkExperienceModel } from '../models/WorkExperience';
import { Translation } from 'react-i18next';
import { WorkExperienceService } from '../services/WorkExperienceService';
import WorkExperienceList from '../components/work-experience/WorkExperienceList';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEmploymentHistoryProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEmploymentHistoryState {
  workExperiences: Array<WorkExperienceModel>;
  loading: boolean;
}

export class EmploymentHistory extends Component<IEmploymentHistoryProps, IEmploymentHistoryState> {
  readonly state: IEmploymentHistoryState;

  private readonly service = new WorkExperienceService();

  constructor(props: IEmploymentHistoryProps) {
    super(props);

    this.state = {
      workExperiences: [],
      loading: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({ loading: true });
    const language = navigator.language.split('-')[0];

    this.service.getLocalizedData(language).then((response) => {
      const sortedData = response;
      sortedData.sort((a: WorkExperienceModel, b: WorkExperienceModel) => {
        return b.startDate.getTime() - a.startDate.getTime();
      });
      this.setState({ workExperiences: sortedData, loading: false });
    });
  }

  render() {
    const { loading } = this.state;
    return (
      <>
        <Box>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item>
              <Typography variant={'h3'} style={{ fontWeight: 'bold', color: '#fff' }}>
                <Translation>{(t) => t('employmentHistoryPage.title')}</Translation>
              </Typography>
            </Grid>
            <Grid item xs={12} mx={5}>
              <WorkExperienceList dataToRender={this.state.workExperiences} />
            </Grid>
          </Grid>
        </Box>
        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}
