import { Box, styled } from '@mui/material';
import { theme } from './muiTheme';

export const SpaceBox = styled(Box)({
  display: 'flex',
  // justifyContent: 'left',
  flexWrap: 'wrap',

  '& > *': {
    margin: theme.spacing(0.5)
  }
});
