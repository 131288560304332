import { getFirestore, Firestore, collection, getDocs } from 'firebase/firestore';
import { ISkillProps } from '../components/skills/Skill';

export class SkillsService {
  private readonly db: Firestore;

  private readonly collectionPath: string = 'skills';

  constructor() {
    this.db = getFirestore();
  }

  public async getSkills(): Promise<ISkillProps[]> {
    const skills: ISkillProps[] = [];

    const skillsRef = collection(this.db, this.collectionPath);
    const skillsSnapshot = await getDocs(skillsRef);
    if (skillsSnapshot.empty) {
      return skills;
    } else {
      skillsSnapshot.docs.forEach((doc) => {
        skills.push({
          name: doc.get('name'),
          rating: doc.get('rating')
        });
      });
      return skills;
    }
  }
}
