import React, { Component } from 'react';
import { Backdrop, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { Education as EducationModel } from '../models/Education';
import { Translation } from 'react-i18next';
import { EducationService } from '../services/EducationService';
import EducationList  from '../components/education/EducationList';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEducationProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEducationState {
  educations: Array<EducationModel>;
  loading: boolean;
}

export class Education extends Component<IEducationProps, IEducationState> {
  readonly state: IEducationState;

  private readonly service = new EducationService();

  constructor(props: IEducationProps) {
    super(props);

    this.state = {
      educations: [],
      loading: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({ loading: true });
    const language = navigator.language.split('-')[0];

    this.service.getLocalizedData(language).then((response) => {
      const sortedData = response;
      sortedData.sort((a: EducationModel, b: EducationModel) => {
        return b.fromDate.getTime() - a.fromDate.getTime();
      });
      this.setState({ educations: sortedData, loading: false });
    });
  }

  render() {
    const { loading } = this.state;
    return (
      <>
        <Container>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item>
              <Typography variant={'h3'} style={{ fontWeight: 'bold', color: '#fff' }}>
                <Translation>{(t) => t('educationPage.title')}</Translation>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <EducationList dataToRender={this.state.educations} />
            </Grid>
          </Grid>
        </Container>
        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}
