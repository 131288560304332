import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';

import { Navigation } from './components/navigation/Navigation';
import { theme } from './theming/muiTheme';
import Footer from './components/Footer';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { navigationRouteMetadata } from './components/navigation/navigationData';

class App extends Component {
  private routeMetadata = navigationRouteMetadata;

  render() {
    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <>
            <CssBaseline />
            <Navigation />
            <Box component={'main'} my={1} sx={{ flexGrow: 1 }}>
              {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
              <Routes>
                {this.routeMetadata.map((entry) => (
                  <Route key={entry.id} path={entry.navigationPath} element={entry.viewComponent} />
                ))}
              </Routes>
            </Box>
            <Footer />
          </>
        </ThemeProvider>
      </BrowserRouter>
    );
  }
}

export default App;
