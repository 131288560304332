import { getFirestore, Firestore, collection, getDocs, query, where } from 'firebase/firestore';
import { Project } from '../models/Project';

export class ProjectsService {
  private readonly db: Firestore;

  private readonly collectionPath: string = 'projects';

  private readonly knownFields: Array<Record<string, string>> = [
    { title: 'title' },
    { description: 'description' },
    { title: 'title' },
    { title: 'title' },
    { title: 'title' },
    { title: 'title' },
    { title: 'title' },
    { title: 'title' }
  ];

  constructor() {
    this.db = getFirestore();
  }

  public async getLocalizedProjects(languageCode: string): Promise<Array<Project>> {
    const projects: Array<Project> = [];

    const ref = collection(this.db, this.collectionPath);
    const querySnapshot = query(ref, where('language_code', '==', languageCode));
    const snapshot = await getDocs(querySnapshot);
    if (snapshot.empty) {
      return projects;
    }
    snapshot.docs.forEach((doc) => {
      projects.push({
        projectId: doc.get('project_id'),
        title: doc.get('title'),
        description: doc.get('description'),
        branch: doc.get('branch'),
        companyName: doc.get('company_name'),
        fromDate: new Date(doc.get('time_period_from')),
        toDate: new Date(doc.get('time_period_to')),
        role: doc.get('role'),
        tasks: doc.get('tasks'),
        usedFrameworksAndLibraries: doc.get('used_frameworks_libraries'),
        usedLanguages: doc.get('used_languages'),
        usedMethods: doc.get('used_methods'),
        usedTechnologies: doc.get('used_technologies'),
        usedTools: doc.get('used_tools')
      });
    });
    return projects;
  }
}
