import { getFirestore, Firestore, collection, getDocs, query, where } from 'firebase/firestore';
import { Education } from '../models/Education';

export class EducationService {
  private readonly db: Firestore;

  private readonly collectionPath: string = 'education';

  private readonly knownFields: Array<Record<string, string>> = [
    { title: 'title' },
    { description: 'description' },
    { title: 'title' },
    { title: 'title' },
    { title: 'title' },
    { title: 'title' },
    { title: 'title' },
    { title: 'title' }
  ];

  constructor() {
    this.db = getFirestore();
  }

  public async getLocalizedData(languageCode: string): Promise<Array<Education>> {
    const data: Array<Education> = [];

    const ref = collection(this.db, this.collectionPath);
    const querySnapshot = query(ref, where('language_code', '==', languageCode));
    const snapshot = await getDocs(querySnapshot);
    if (snapshot.empty) {
      return data;
    }
    snapshot.docs.forEach((doc) => {
      data.push({
        educationId: doc.get('education_entry_id'),
        educationalInstitutionName: doc.get('educational_institution_name'),
        educationalInstitutionLocation: doc.get('educational_institution_location'),
        achievedDegree: doc.get('achieved_degree'),
        thesis: doc.get('thesis'),
        fromDate: new Date(doc.get('start_date')),
        toDate: new Date(doc.get('end_date')),
      });
    });
    return data;
  }
}
