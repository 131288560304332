import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import { SkillAssessmentBar } from '../../theming/SkillAssessmentBar';
import { Grid } from '@mui/material';

interface ISkillState {
  skillExtended: boolean;
  ratingProgress: number;
}

export interface ISkillProps {
  key?: number;
  name: string;
  rating: number;
}

export class Skill extends Component<ISkillProps, ISkillState> {
  public state!: ISkillState;
  public props!: ISkillProps;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private timer?: any;

  constructor(props: ISkillProps) {
    super(props);
    this.state = { skillExtended: false, ratingProgress: 0 };
  }

  private startTimer() {
    this.timer = setInterval(() => {
      const newProgressValue = this.state.ratingProgress + 10;

      if (this.state.ratingProgress !== this.props.rating) {
        this.setState({ ratingProgress: newProgressValue });
      }
    }, 100);
  }

  private stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  componentDidMount() {
    this.startTimer();
  }

  componentDidUpdate() {
    if (this.state.ratingProgress === this.props.rating * 10) {
      this.stopTimer();
    }
  }

  render() {
    return (
      <Grid container spacing={1} mb={2}>
        <Grid item xs={12} md={3} sx={{ display: { xs: 'block', md: 'none' } }}>
          <Typography variant="h4" align={'center'} style={{ color: '#fff' }}>
            {this.props.name}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Typography variant="h4" align={'center'} style={{ color: '#fff' }}>
            {this.props.name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} sx={{ display: 'flex', alignItems: 'center' }}>
          <SkillAssessmentBar variant="determinate" value={this.state.ratingProgress} />
        </Grid>
      </Grid>
    );
  }
}
