import React, { Component } from 'react';
import {
  AppBar,
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar
} from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

import { navigationRouteMetadata } from './navigationData';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface INavigationProps {}

// Currently not used, maybe in the future
interface INavigationState {
  navigationOpen: boolean;
}

export class Navigation extends Component<INavigationProps, INavigationState> {
  public state!: INavigationState;

  private navigationMedatada = navigationRouteMetadata;

  constructor(props: INavigationProps) {
    super(props);

    this.state = { navigationOpen: false };

    // This binding is necessary to make `this` work in the callback
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  private toggleDrawer() {
    this.setState({ navigationOpen: !this.state.navigationOpen });
  }

  private isCurrentSite(navigationPath: string) {
    return location.pathname == navigationPath;
  }

  render() {
    return (
      <Box component={'nav'}>
        <AppBar component={'nav'}>
          <Toolbar>
            <IconButton onClick={this.toggleDrawer} color={'inherit'}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Box component={'nav'}>
          <SwipeableDrawer
            anchor="left"
            open={this.state.navigationOpen}
            onClose={this.toggleDrawer}
            onOpen={this.toggleDrawer}
          >
            <List>
              {this.navigationMedatada.map((entry) => (
                <ListItemButton
                  key={entry.id}
                  component={Link}
                  to={entry.navigationPath}
                  onClick={this.toggleDrawer}
                  selected={this.isCurrentSite(entry.navigationPath)}
                >
                  <ListItemIcon>{entry.navIconComponent}</ListItemIcon>
                  <ListItemText>{entry.navEntryComponent}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </SwipeableDrawer>
        </Box>
      </Box>
    );
  }
}
