import React from 'react';
import { Home } from '../../views/Home';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { ReactComponent as SkillsIcon } from '../../theming/icons/nav_skills.svg';
import { ReactComponent as ProjectsIcon } from '../../theming/icons/nav_projects.svg';
import { ReactComponent as ContactIcon } from '../../theming/icons/nav_contact.svg';
import { ReactComponent as EmploymentIcon } from '../../theming/icons/nav_employment.svg';
import { ReactComponent as EducationIcon } from '../../theming/icons/nav_education.svg';
import { ReactComponent as AboutMeIcon } from '../../theming/icons/nav_about-me.svg';
import { ReactComponent as AboutWebsiteIcon } from '../../theming/icons/nav_about-website.svg';
import { Translation } from 'react-i18next';
import { Skills } from '../../views/Skills';
import { SvgIcon } from '@mui/material';
import { EmploymentHistory } from '../../views/EmploymentHistory';
import { Education } from '../../views/Education';
import { Projects } from '../../views/Projects';
import { Contact } from '../../views/Contact';
import { AboutWebsite } from '../../views/AboutWebsite';
import { AboutMyself } from '../../views/AboutMyself';

export enum SitePages {
  HOME,
  SKILLS,
  EMPLOYMENTHISTORY,
  EDUCATION,
  PROJECTS,
  ABOUTME,
  ABOUTWEBSITE,
  CONTACT
}

export interface NavigationData {
  id: SitePages;
  navigationPath: string;
  viewComponent: React.ReactNode;
  navIconComponent: React.ReactNode;
  navEntryComponent: React.ReactNode;
}

export const navigationRouteMetadata: NavigationData[] = [
  // Homepage
  {
    id: SitePages.HOME,
    navigationPath: '/',
    viewComponent: <Home />,
    navIconComponent: <HomeOutlinedIcon fontSize={'large'} />,
    navEntryComponent: <Translation>{(t) => t('navigation.home')}</Translation>
  },
  // Skills
  {
    id: SitePages.SKILLS,
    navigationPath: '/skills',
    viewComponent: <Skills />,
    navIconComponent: (
      <SvgIcon fontSize={'large'}>
        <SkillsIcon />{' '}
      </SvgIcon>
    ),
    navEntryComponent: <Translation>{(t) => t('navigation.skills')}</Translation>
  },
  // Employment History
  {
    id: SitePages.EMPLOYMENTHISTORY,
    navigationPath: '/employment-history',
    viewComponent: <EmploymentHistory />,
    navIconComponent: (
      <SvgIcon fontSize={'large'}>
        <EmploymentIcon />{' '}
      </SvgIcon>
    ),
    navEntryComponent: <Translation>{(t) => t('navigation.employment_history')}</Translation>
  },
  // Education
  {
    id: SitePages.EDUCATION,
    navigationPath: '/education',
    viewComponent: <Education />,
    navIconComponent: (
      <SvgIcon fontSize={'large'}>
        <EducationIcon />{' '}
      </SvgIcon>
    ),
    navEntryComponent: <Translation>{(t) => t('navigation.education')}</Translation>
  },
  // Projects
  {
    id: SitePages.PROJECTS,
    navigationPath: '/projects',
    viewComponent: <Projects />,
    navIconComponent: (
      <SvgIcon fontSize={'large'}>
        <ProjectsIcon />{' '}
      </SvgIcon>
    ),
    navEntryComponent: <Translation>{(t) => t('navigation.projects')}</Translation>
  },
  // About me
  {
    id: SitePages.ABOUTME,
    navigationPath: '/about-myself',
    viewComponent: <AboutMyself />,
    navIconComponent: (
      <SvgIcon fontSize={'large'}>
        <AboutMeIcon />{' '}
      </SvgIcon>
    ),
    navEntryComponent: <Translation>{(t) => t('navigation.about-myself')}</Translation>
  },
  // About the website (GitHub Repo
  {
    id: SitePages.ABOUTWEBSITE,
    navigationPath: '/about-website',
    viewComponent: <AboutWebsite />,
    navIconComponent: (
      <SvgIcon fontSize={'large'}>
        <AboutWebsiteIcon />{' '}
      </SvgIcon>
    ),
    navEntryComponent: <Translation>{(t) => t('navigation.about-website')}</Translation>
  },
  // Contact (LinkedIn, Xing, Mail, Github Repo
  {
    id: SitePages.CONTACT,
    navigationPath: '/contact',
    viewComponent: <Contact />,
    navIconComponent: (
      <SvgIcon fontSize={'large'}>
        <ContactIcon />{' '}
      </SvgIcon>
    ),
    navEntryComponent: <Translation>{(t) => t('navigation.contact')}</Translation>
  }
];
