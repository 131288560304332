import * as React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';
import { Component } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IFooterProps {}

// Currently not used, maybe in the future
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IFooterState {}

export default class Footer extends Component<IFooterProps, IFooterState> {
  public state!: IFooterState;

  constructor(props: IFooterProps) {
    super(props);
  }

  render() {
    return (
      <Box className={'app-footer'} component={'footer'}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid item>
              <Grid item>
                <>
                  {'© '}
                  <Link color="inherit" href={window.location.origin}>
                    Liviu Cristea
                  </Link>{' '}
                  {new Date().getFullYear()}
                </>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant={'caption'}>
              {'Free SVG Background by '}
              <Link href="https://bgjar.com" target={'_blank'} rel="sponsored" title="bgjar">
                BGJar
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
