import React, { Component } from 'react';
import { Backdrop, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { ProjectsService } from '../services/ProjectsService';
import { Project } from '../models/Project';
import { ProjectList } from '../components/projects/ProjectList';
import { Translation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProjectsProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProjectsState {
  projects: Array<Project>;
  loading: boolean;
}

export class Projects extends Component<IProjectsProps, IProjectsState> {
  readonly state: IProjectsState;

  private readonly projectService = new ProjectsService();

  constructor(props: IProjectsProps) {
    super(props);

    this.state = {
      projects: [],
      loading: false
    };
  }

  componentDidMount() {
    this.loadProjects();
  }

  loadProjects() {
    this.setState({ loading: true });
    const language = navigator.language.split('-')[0];

    this.projectService.getLocalizedProjects(language).then((response) => {
      const sortedProjects = response;
      sortedProjects.sort((a: Project, b: Project) => {
        return b.fromDate.getTime() - a.fromDate.getTime();
      });
      this.setState({ projects: sortedProjects, loading: false });
    });
  }

  render() {
    const { loading } = this.state;
    return (
      <>
        <Container>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item>
              <Typography variant={'h3'} style={{ fontWeight: 'bold', color: '#fff' }}>
                <Translation>{(t) => t('projectsPage.title')}</Translation>
              </Typography>
            </Grid>
            <Grid item>
              <ProjectList projectsToRender={this.state.projects} />
            </Grid>
          </Grid>
        </Container>
        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}
