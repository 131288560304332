import React, { Component } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { Translation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IAboutWebsiteProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IAboutWebsiteState {}

export class AboutWebsite extends Component<IAboutWebsiteProps, IAboutWebsiteState> {
  constructor(props: IAboutWebsiteProps) {
    super(props);
  }

  render() {
    return (
      <Container>
        <Grid container spacing={2} justifyContent={'center'}>
          <Grid item>
            <Typography variant={'h3'} style={{ fontWeight: 'bold', color: '#fff' }}>
              <Translation>{(t) => t('aboutWebsitePage.title')}</Translation>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
