import { getFirestore, Firestore, collection, getDocs, query, where } from 'firebase/firestore';
import { WorkExperience } from '../models/WorkExperience';

export class WorkExperienceService {
  private readonly db: Firestore;

  private readonly collectionPath: string = 'work_experience';

  constructor() {
    this.db = getFirestore();
  }

  public async getLocalizedData(languageCode: string): Promise<Array<WorkExperience>> {
    const data: Array<WorkExperience> = [];

    const ref = collection(this.db, this.collectionPath);
    const querySnapshot = query(ref, where('language_code', '==', languageCode));
    const snapshot = await getDocs(querySnapshot);
    if (snapshot.empty) {
      return data;
    }
    snapshot.docs.forEach((doc) => {
      data.push({
        entryId: doc.get('entry_id'),
        employmentPosition: doc.get('employment_position'),
        employer: doc.get('employer'),
        subActivities: doc.get('sub_activities'),
        mainActivity: doc.get('main_activity'),
        startDate: new Date(doc.get('start_date')),
        endDate: new Date(doc.get('end_date'))
      });
    });
    return data;
  }
}
