import React, { Component } from 'react';

import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { BusinessCenterOutlined } from '@mui/icons-material';
import { WorkExperience } from '../../models/WorkExperience';
import WorkExperienceDetails from './WorkExperienceDetails';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWorkExperienceState {}

export interface IWorkExperienceProps {
  data: WorkExperience;
  elementId: number;
  displayConnector: boolean;

  // animate: boolean;
  timeout: number;
}

class WorkExperienceEntry extends Component<IWorkExperienceProps, IWorkExperienceState> {
  public state!: IWorkExperienceState;
  public props!: IWorkExperienceProps;

  constructor(props: IWorkExperienceProps) {
    super(props);

    this.state = { animate: false };

    // this.finished = this.finished.bind(this);
  }

  finished() {
    // this.props.onAnimationFinished(this.props.elementId);
  }

  render() {
    const { data, displayConnector, timeout } = this.props;
    // const dateOptions: Intl.DateTimeFormatOptions = {
    //   year: 'numeric',
    //   month: '2-digit'
    // };

    const content = (
      <WorkExperienceDetails
        employerName={data.employer.name}
        employerCity={data.employer.city}
        employmentPosition={data.employmentPosition}
        mainActivity={data.mainActivity}
        subActivities={data.subActivities}
        dateFrom={data.startDate}
        dateTo={data.endDate}
        animationTimeout={timeout}
      />
    );

    return (
      <TimelineItem sx={{ m: 'auto 0' }}>
        <TimelineSeparator>
          <TimelineDot color={'secondary'} variant={'outlined'}>
            <BusinessCenterOutlined color={'secondary'} fontSize={'large'} />
          </TimelineDot>
          {displayConnector && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>{content}</TimelineContent>
      </TimelineItem>
    );
  }
}

export default WorkExperienceEntry;
