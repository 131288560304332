import React, { Component } from 'react';
import { Grow, Paper, Typography } from '@mui/material';
import { Translation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEducationDetailsState {}

export interface IEducationDetailsProps {
  institutionName: string;
  institutionLocation: string;
  achievedDegree: string;
  thesis: string;
  dateFrom: Date;
  dateTo: Date;

  animationTimeout: number;
}

class EducationDetails extends Component<IEducationDetailsProps, IEducationDetailsState> {
  public state!: IEducationDetailsState;
  public props!: IEducationDetailsProps;

  constructor(props: IEducationDetailsProps) {
    super(props);
  }

  render() {
    const { institutionName, institutionLocation, achievedDegree, dateFrom, dateTo, animationTimeout } = this.props;
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit'
    };

    return (
      <Grow in={true} timeout={animationTimeout} mountOnEnter unmountOnExit>
        <Paper elevation={3} color={'secondary'} style={{ padding: '4px 15px 10px' }}>
          <Typography variant="h3" color={'textSecondary'}>
            {dateFrom.getFullYear() + ' - ' + dateTo.getFullYear()}
          </Typography>
          <Typography variant={'h5'} style={{ fontWeight: 'bold' }}>
            {institutionName}
          </Typography>
          <Typography variant={'body1'}>{institutionLocation}</Typography>
          <Typography color="textSecondary">
            {dateFrom.toLocaleDateString(navigator.language, dateOptions) +
              ' - ' +
              dateTo.toLocaleDateString(navigator.language, dateOptions)}
          </Typography>
          <Typography color={'textSecondary'}>
            <Translation>{(t) => t('educationPage.achievedDegree')}</Translation>:&nbsp;
            {achievedDegree}
          </Typography>
        </Paper>
      </Grow>
    );
  }
}

export default EducationDetails;
